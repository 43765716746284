body {
  margin: 0;
  padding: 0;
  background-color: #E3F2FD;
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pink {
  background-color: #FFC8DD;
  color: #560bad;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}