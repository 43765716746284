.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pink-button {
  background-color: #FFC8DD;
}

.pink-button:hover {
  background-color: #FFAFCC;
}

h1 {
  font-size: 2rem;
  text-align: center;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 960px;
  height: 540px;
  margin-bottom: 2rem;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text_area {
  padding-left: 10rem;
  padding-right: 10rem;
  max-width: 960px;
  text-align: left;
  font-size: 1.8rem;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

button {
  background-color: #E3F2FD;
  color: black;
  font-size: 5rem;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #90CAF9;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.feedback-form form {
  text-align: center; /* Center align for the form elements */
}

.feedback-form textarea {
  margin-bottom: 5px; /* More space between the text area and the submit button */
}

.feedback-form input[type="submit"] {
  padding: 10px 20px; /* Increase the button's padding */
  border: none; /* Remove the default border */
  border-radius: 5px; /* Rounded corners */
  background-color: #912f81; /* Attractive background color */
  color: white; /* Text color */
  font-size: 16px; /* Increased font size */
  cursor: pointer; /* Hand pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin-bottom: 50px; /* More space after button and before image */
}

.feedback-form input[type="submit"]:hover {
  background-color: #701e59; /* Darker color on hover */
}

.artist-name {
  color: #000000; /* Custom font color for the artist's name; replace with your desired color */
  margin-top: 0px; /* Added some margin top for spacing */
}

@media screen and (max-width: 768px) {
  h1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .video-container {
    width: 100%;
    height: 100%;
  }

  .text_area {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.0rem;
  }
}